import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";

import LinkImg from "../../../assets/images/link.svg";

export const ToV2Elementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      Complete the sentences using the correct form of the verbs in parentheses.
    </span>
  );
  const questions = [
    {
      id: 1,
      question: <span>I ____ a new language this year. (want / learn)</span>,
      answer: (
        <span>
          I <span className="font-bold">want to learn</span> a new language this
          year.
        </span>
      ),
    },
    {
      id: 2,
      question: <span>We ____ some groceries for dinner.(need / buy)</span>,
      answer: (
        <span>
          We <span className="font-bold">need to buy</span> some groceries for
          dinner.
        </span>
      ),
    },
    {
      id: 3,
      question: (
        <span>
          She ____ her grandparents this weekend. (would like / visit)
        </span>
      ),
      answer: (
        <span>
          She <span className="font-bold">would like to visit</span> her
          grandparents this weekend.
        </span>
      ),
    },
    {
      id: 4,
      question: (
        <span>Last night, he ____ for his mistake. (refuse / apologize)</span>
      ),
      answer: (
        <span>
          Last night, he <span className="font-bold">refused to apologize</span>{" "}
          for his mistake.
        </span>
      ),
    },
    {
      id: 5,
      question: <span>He ____ a bicycle these days. (learn / ride)</span>,
      answer: (
        <span>
          He <span className="font-bold">is learning to ride</span> a bicycle
          these days.
        </span>
      ),
    },
    {
      id: 6,
      question: <span>____ me as soon as you arrive. (promise / call)</span>,
      answer: (
        <span>
          <span className="font-bold">Promise to call</span> me as soon as you
          arrive.
        </span>
      ),
    },
    {
      id: 7,
      question: <span>We ____ hiking next Saturday. (plan / go)</span>,
      answer: (
        <span>
          We <span className="font-bold">plan to go</span> hiking next Saturday.
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">V.1 + to + V.2</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            V.1 + to + V.2
          </h1>
          <p className="mb-5">
            After some verbs, like "want", "would like", "need", "decide",
            "refuse" etc., if there is a second verb, it is in the infinitive
            form with "to."
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-20">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">
              I <span className="font-bold">want to go</span> shopping.
            </p>
            <p className="pb-2">
              We <span className="font-bold">need to buy</span> some vegetables.
            </p>
            <p className="pb-2">
              She <span className="font-bold">would like to visit</span> the
              museum.
            </p>
            <p className="pb-2">
              He <span className="font-bold">decided to find</span> a new job.
            </p>
          </div>

          <div className="mb-8">
            <p className="font-bold mb-1">RELATED TOPIC</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/v2ing">
                V.1 + V.2-ing <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            V.1 + to + V.2
          </h1>
          <p lang="fa" dir="rtl" className="mb-5">
            بعد از برخی افعال مانند want ،need ،would like ،refuse ،decide و
            ...، اگر فعل دومی در جمله داشته باشیم، آن فعل دوم به شکل مصدر (ساده)
            با to استفاده می شود.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-20">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-2">
              I <span className="font-bold">want to go</span> shopping.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              می خواهم بروم خرید.
            </p>
            <p className="pb-1">
              We <span className="font-bold">need to buy</span> some vegetables.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              نیاز داریم کمی سبزیجات بخریم.
            </p>
            <p className="pb-1">
              She <span className="font-bold">would like to visit</span> the
              museum.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              می خواهد موزه را ببیند.
            </p>
            <p className="pb-1">
              He <span className="font-bold">decided to find</span> a new job.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              او تصمیم گرفت یک شغل جدید پیدا کند.
            </p>
          </div>

          <div className="mb-8">
            <p className="font-bold mb-1">RELATED TOPIC</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/v2ing">
                V.1 + V.2-ing <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
